<template>
  <BaseDetail
    :is-loading="loading"
    :is-scroll="false"
  >
    <template #sidebar>
      <BaseSideBar dark>
        <SideBar
          :is-loading="loading"
          v-model="selectedItem"
          :eav-entity.sync="filteredDetail"
        />
      </BaseSideBar>
    </template>
    <template #header>
      <BaseHeader
        @save="handleSave"
        @close="handleClose"
        @dataBaseDelete="isDataBaseDelete = true"
        :deleteDataBaseBtn="$can(null, 'editConstructor') && $route.params.id !== 'create'"
        :save-btn="$can(null, 'editConstructor') && !!detail.entityGeometry"
        :delete-btn="false"
        :close-btn="true"
        text-delete="Вы действительно хотите удалить объект?"
      />
    </template>
    <template #content>
      <DetailOwn
        v-if="selectedItem.type === 'own'"
        :eav-entity.sync="detail"
        :selected-item="selectedItem"
      />
      <DetailView
        v-else
        :eav-entity.sync="detail"
        v-model="attributeInput"
        :selected-item="selectedItem"
        ref="content"
        @moveAttribute="moveAttribute"
        @moveAttributeIntoMain="moveAttributeIntoMain"
        :key="selectedItem.index+'-'+selectedItem.type"
      />
      <BaseDeleteConfirm
        v-if="detail"
        :id="Number(detail.id)"
        :isDialog="isDataBaseDelete"
        entity-text="объект"
        @closeDialog="isDataBaseDelete = false"
        @delete="handleDelete(1)"
      />
    </template>
  </BaseDetail>
</template>

<script>
import BaseDetail from '@/components/base/BaseDetail'
import DetailView from './detail/DetailView'
import detail from '@/components/mixins/detail'
import SideBar from '@/components/views/account/constructor/detail/SideBar'
import BaseHeader from '@/components/base/BaseDetail/BaseHeader'
import BaseSideBar from '@/components/base/BaseSideBar'
import {
  cleanClone,
  filterAttributes,
  flatenObject,
  getParentPath,
  recLookup,
  sortWithNull
} from '@/components/utils/common'
import DetailOwn from '@/components/views/account/constructor/detail/DetailOwn'
import constructorValidation from '@/components/mixins/validation/constructor'
import BaseDeleteConfirm from '@/components/base/BaseDeleteConfirm'

export default {
  mixins: [detail, constructorValidation],
  name: 'Detail',
  components: { DetailOwn, BaseSideBar, BaseHeader, BaseDetail, DetailView, SideBar, BaseDeleteConfirm },
  data () {
    return {
      isDataBaseDelete: false,
      detail: {
        baseAttributes: [],
        eavAttributes: []
      },
      selectedItem: {
        type: 'own',
        index: [0]
      }
    }
  },
  computed: {
    loading () {
      let isLoading = false
      if (this.isHandbookLoading) {
        isLoading = true
      } else if (this.isServiceDataLoading) {
        isLoading = true
      } else if (this.isLoading.data) {
        isLoading = true
      }
      return isLoading
    },
    eavAttributes () {
      return this.detail.eavAttributes.filter(item => !item.parent_id)
    },
    baseAttributes () {
      return filterAttributes(this.detail.baseAttributes, ['id', 'geometry', 'state'])
    },
    filteredDetail: {
      get () {
        let detail = cleanClone(this.detail)
        detail.eavAttributes = this.eavAttributes
        detail.baseAttributes = this.baseAttributes
        return detail
      },
      set (value) {
        value.baseAttributes = this.detail.baseAttributes
        this.detail = value
      }
    },
    attributeInput: {
      get () {
        let attribute
        if (this.selectedItem.type === 'base') {
          attribute = this.baseAttributes[this.selectedItem.index[0]]
        } else if (this.selectedItem.type === 'custom') {
          attribute = recLookup(this.detail.eavAttributes, getParentPath(this.selectedItem.index))[this.selectedItem.index.slice(-1)[0]]
        }
        return attribute
      },
      set (val) {
        this.$v.$touch()
        if (this.selectedItem.type === 'custom') {
          let oldValues = recLookup(this.detail.eavAttributes, getParentPath(this.selectedItem.index))
          let oldValue = oldValues[this.selectedItem.index.slice(-1)[0]]
          if (oldValue.name !== val.name) {
            this.generateUniqueName(val)
          }
          if (val.eavType.id === 9) {
            val.relatedAttributes.forEach((relatedAttribute, index) => {
              if (oldValue.relatedAttributes[index]?.name !== relatedAttribute?.name) {
                this.generateUniqueName(relatedAttribute)
              }
            })
          }
          oldValues.splice(this.selectedItem.index.slice(-1)[0], 1, val)
        }
      }
    }
  },
  methods: {
    moveAttribute (item) {
      const indexFolder = this.detail.eavAttributes.findIndex((attr) => attr.id === item.id)
      const indexOrigin = this.detail.eavAttributes.findIndex((attr) => attr.id === this.attributeInput.id)
      if (indexOrigin >= 0) { //если аттрибут в основном списке
        this.$set(this.detail.eavAttributes[indexOrigin], 'parent_id', item.id) //меняем его parent_id на id папки, в которую помещаем
        this.detail.eavAttributes[indexFolder].relatedAttributes.push(this.attributeInput) //пушим этот атрибут в список атрибутов нужной папки
        this.detail.eavAttributes.splice(indexOrigin, 1) //удаляем из основного списка
      } else if (this.attributeInput.parent_id) { //если внутри папки
        const indexOriginFolder = this.detail.eavAttributes.findIndex((attr) => attr.id === this.attributeInput.parent_id)
        const indexOriginInFolder = this.detail.eavAttributes[indexOriginFolder].relatedAttributes.findIndex((attr => attr.id === this.attributeInput.id))
        this.$set(this.detail.eavAttributes[indexOriginFolder].relatedAttributes[indexOriginInFolder], 'parent_id', item.id)//меняем его parent_id на id папки, в которую помещаем
        this.detail.eavAttributes[indexFolder].relatedAttributes.push(this.attributeInput)//пушим этот атрибут в список атрибутов нужной папки
        this.detail.eavAttributes[indexOriginFolder].relatedAttributes.splice(indexOrigin, 1)//удаляем из списка атрибутов старой папки
      }
    },
    moveAttributeIntoMain (item) {
      this.detail.eavAttributes.push(this.attributeInput) //добавление в основной список
      const indexOriginFolder = this.detail.eavAttributes.findIndex((attr) => attr.id === this.attributeInput.parent_id)
      this.$set(this.attributeInput, 'parent_id', null) // в основном списке у эл. не должно быть parent_id
      const indexOriginInFolder = this.detail.eavAttributes[indexOriginFolder].relatedAttributes.findIndex((attr => attr.id === this.attributeInput.id))
      this.detail.eavAttributes[indexOriginFolder].relatedAttributes.splice(indexOriginInFolder, 1) // удаляю элемент из папки
    },
    processResponse (response) {
      if (response && response.eavAttributes) {
        response.eavAttributes.sort(sortWithNull('order'))
      }
      this.detail = response
    },
    generateUniqueName (attribute) {
      if (attribute.name.length > 31) {
        attribute.name = attribute.name.slice(0, 31)
      }
      let copyNumber = 0
      const copyNumberLimit = 30 //note: для теста, чтобы не вешать систему, позже можно будет снести
      const originName = attribute.name

      while (!this.isNameUnique(attribute.name)) {
        if (copyNumber > copyNumberLimit) {
          console.log('isNameUnique error')
          break
        }
        copyNumber++
        attribute.name = originName
        if (attribute.name.length >= 31) {
          attribute.name = attribute.name.slice(0, -(String(copyNumber).length))
        }
        attribute.name += copyNumber
      }
    },
    collectAttributesRecursive () {
      return flatenObject(this.detail.eavAttributes, 'relatedAttributes')
        .concat(flatenObject(this.detail.baseAttributes, 'relatedAttributes'))
    },
    isNameUnique (name) {
      return !this.collectAttributesRecursive().filter(item => item.name === name).length
    }
  }
}
</script>

<style scoped>

</style>
