<template>
  <div>
    <RelatedAttributesItem
      class="mt-4"
      v-for="(relatedAttribute, index) in relatedAttributes"
      :key="index + '-' + optionCategoryId"
      :value="relatedAttribute"
      @input="handleInput($event, index)"
      :validation-path="validationPath + `.relatedAttributes.$each.${index}`"
    />
  </div>
</template>

<script>
import RelatedAttributesItem
  from '@/components/views/account/constructor/detail/attributeSettings/complexOptionSettings/RelatedAttributesItem'
import Attribute from '@/components/structures/constructor/objectAttribute'
import AttributeRule from '@/components/structures/constructor/attributeRule'
import AttributeType from '@/components/structures/constructor/attributeType'
import CyrillicToTranslit from 'cyrillic-to-translit-js'
import { cleanClone, deepEqual } from '@/components/utils/common'

const cyrillicToTranslit = new CyrillicToTranslit()

export default {
  name: 'RelatedAttributesList',
  components: { RelatedAttributesItem },
  props: {
    parentId: Number,
    optionCategoryId: Number,
    value: Array,
    validationPath: String,
    entityId: Number
  },
  data () {
    return {
      relatedAttributes: cleanClone(this.value)
    }
  },
  computed: {
    category () {
      return this.optionCategoryId ? this.$store.getters['handbook/getListItemById'](this.optionCategoryId) : {}
    }
  },
  watch: {
    optionCategoryId: {
      handler () {
        this.$set(this, 'relatedAttributes', [])
        if (this.category.categoryGroups) {
          this.category.categoryGroups.forEach(item => {
            let attribute = new Attribute()
            attribute.parent_id = this.parentId
            attribute.attributeRule = new AttributeRule(2)
            attribute.attributeRule.required = false
            attribute.attributeRule.locked = false
            attribute.eavType = new AttributeType(2)
            attribute.optionCategoryId = item.category.id
            attribute.name = cyrillicToTranslit.transform(item.category.name, '_').toLowerCase()
            attribute.label = item.category.name
            attribute.entityId = this.entityId

            this.relatedAttributes.push(attribute)
          })
        }
      }
    },
    relatedAttributes: {
      async handler (newVal) {
        if (!deepEqual(newVal, this.value)) {
          this.$emit('input', cleanClone(this.relatedAttributes))
          return this.$nextTick(() => {
            this.$emit('changeFilter', !!this.relatedAttributes.filter(attribute => attribute.is_filter).length)
            this.$emit('changeShowInTable', !!this.relatedAttributes.filter(attribute => attribute.show_in_table).length)
            return true
          })
        }
      },
      deep: true
    },
    value: {
      handler (newVal) {
        if (!deepEqual(newVal, this.relatedAttributes)) {
          this.relatedAttributes = cleanClone(this.value)
        }
      },
      deep: true
    }
  },
  methods: {
    handleInput (data, index) {
      if (!deepEqual(this.relatedAttributes[index], data)) {
        this.relatedAttributes.splice(index, 1, data)
      }
    }
  }
}
</script>

<style scoped>

</style>
