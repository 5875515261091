<template>
  <div>
    <OptionSettings
      v-model="localAttribute"
      :category-to-filter="typeByLabel('Составной список')"
    />
  </div>
</template>

<script>
import OptionSettings from '@/components/views/account/constructor/detail/attributeSettings/OptionSettings'
import serviceDataComputeds from '@/components/mixins/serviceDataComputeds'
import attributeRules from '@/components/mixins/attributeRules'

export default {
  mixins: [serviceDataComputeds, attributeRules],
  name: 'ComplexOptionSettings',
  components: { OptionSettings, BaseTree: () => import('@/components/base/BaseTree') }
}
</script>

<style scoped>

</style>
