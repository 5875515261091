<template>
  <v-card outlined>
    <v-card-text class="d-flex flex-row">
      <div
        style="width: 40%"
        class="mr-4"
      >
        <v-text-field
          outlined
          dense
          v-model="localAttribute.label"
          @change="handleLabelChange"
          :label="$t('constructor.label')"
          :error-messages="getErrorMessage(validationPath + '.label')"
        ></v-text-field>
        <v-text-field
          outlined
          dense
          counter="31"
          maxlength="31"
          v-model="localAttribute.name"
          @change="handleNameChange"
          label="Алиас"
          :error-messages="getErrorMessage(validationPath + '.name')"
        ></v-text-field>
        <v-text-field
          outlined
          dense
          v-model="localAttribute.order"
          @change="handleLocalAttributeChange"
          :label="$t('constructor.order')"
        ></v-text-field>
      </div>

      <div>
        <v-checkbox
          class="mt-0 pt-0"
          outlined
          dense
          hide-details
          v-model="localAttribute.show_in_table"
          @change="handleLocalAttributeChange"
          :label="$t('constructor.showInTable')"
        ></v-checkbox>
        <v-checkbox
          outlined
          dense
          hide-details
          v-model="localAttribute.attributeRule.locked"
          @change="handleLocalAttributeChange"
          :label="$t('constructor.locked')"
        ></v-checkbox>
        <v-checkbox
          outlined
          dense
          hide-details
          @change="handleLocalAttributeChange"
          v-model="localAttribute.is_filter"
          :label="$t('constructor.filter')"
        ></v-checkbox>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import validationErrors from '@/components/mixins/validationErrors'
import constructorAttributes from '@/components/mixins/constructorAttributes'

export default {
  mixins: [validationErrors, constructorAttributes],
  name: 'RelatedAttributesItem',
  props: {
    validationPath: String
  },
  inject: ['$v'],
  methods: {
    getErrorMessage (path) {
      return this.checkErrors(`detail.eavAttributes.$each.${path}`)
    }
  }
}
</script>

<style scoped>

</style>
