<template>
  <v-list>
    <v-list-item>
      <v-list-item-content class="d-flex flex-row flex-nowrap justify-space-between">
        <v-btn
          :disabled="!$can(null, 'editConstructor') || isLoading"
          small
          text
          @click="handleCreate()"
          class="mb-4 pa-0"
        >
          {{ $t('base.createAttr') }}
          <v-icon small>mdi-plus</v-icon>
        </v-btn>
      </v-list-item-content>
    </v-list-item>
    <OwnItems
      :eav-entity="eavEntity"
      :active-index-list.sync="ownIndex"
    />
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title>{{ $t('constructor.customAttributes') }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <!--note: не используем v-model, элемент выбирается через index-list.sync-->
    <v-list-item-group>
      <SidebarItem
        v-for="(item, i) in eavEntity.eavAttributes"
        :key="i+'-'+eavEntity.eavAttributes.length"
        :item="item"
        :item-index="i"
        :active-index-list.sync="customIndex"
        @create="handleCreate"
        @delete="handleItemToDelete"
      />
    </v-list-item-group>
    <!--todo: пока просто скрыл, в дальнейшем вообще уберем baseAttributes-->
    <!--todo: пока раскрыл, дальше скрою или уже уберем.-->
<!--    <v-list-item>
      <v-list-item-content>
        <v-list-item-title>
          {{ $t('constructor.baseAttributes') }}
        </v-list-item-title>
      </v-list-item-content>
      <v-list-item-action>
        <v-btn
          :disabled="!$can(null, 'editConstructor')"
          small
          icon
          @click="isOpen = !isOpen"
        >
          <v-icon>{{ isOpen ? 'mdi-chevron-down' : 'mdi-chevron-right' }}</v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>-->
    <v-list-item-group>
      <SidebarItem
        v-show="isOpen"
        v-for="(item, i) in baseAttributes"
        :key="i"
        :item="item"
        :item-index="i"
        :active-index-list.sync="baseIndex"
        hide-actions
      />
    </v-list-item-group>
    <BaseDialog
      role="delete"
      is-cancel-btn
      is-confirm-btn
      :text="$t('message.areYouSure')"
      v-model="dataToDelete.isDeleteDialog"
      @confirm="handleDelete"
      :max-width="350"
    />
  </v-list>
</template>

<script>
import Attribute from '@/components/structures/constructor/objectAttribute'
import validationErrors from '@/components/mixins/validationErrors'
import SidebarItem from '@/components/views/account/constructor/detail/sideBar/SidebarItem'
import { cleanClone, deepEqual, getParentPath, recLookup } from '@/components/utils/common'
import OwnItems from '@/components/views/account/constructor/detail/sideBar/OwnItems'
import BaseDialog from '@/components/base/BaseDialog'

export default {
  components: {
    BaseDialog,
    OwnItems,
    SidebarItem
  },
  mixins: [validationErrors],
  name: 'SideBar',
  props: {
    value: Object,
    eavEntity: Object,
    isLoading: Boolean
  },
  inject: ['$v'],
  data () {
    return {
      isOpen: false,
      baseIndex: [0],
      customIndex: [],
      ownIndex: [],
      dataToDelete: {
        isDeleteDialog: false,
        index: null // массив индексов с путем для удаления элемента
      }
    }
  },
  computed: {
    baseAttributes () {
      return this.eavEntity.baseAttributes
    }
  },
  watch: {
    value: {
      handler () {
        switch (this.value.type) {
          case 'base':
            this.baseIndex = this.value.index
            this.customIndex = []
            break
          case 'custom':
            this.baseIndex = []
            this.customIndex = this.value.index
            break
        }
      },
      deep: true,
      immediate: true
    },
    baseIndex: {
      handler () {
        if (this.baseIndex.length) {
          this.customIndex = []
          this.ownIndex = []
          this.$emit('input', { type: 'base', index: this.baseIndex })
        }
      },
      deep: true
    },
    customIndex: {
      handler () {
        if (this.customIndex.length) {
          this.baseIndex = []
          this.ownIndex = []
          this.$emit('input', { type: 'custom', index: this.customIndex })
        }
      },
      deep: true
    },
    ownIndex: {
      handler () {
        if (this.ownIndex.length) {
          this.baseIndex = []
          this.customIndex = []
          this.$emit('input', { type: 'own', index: this.ownIndex })
        }
      },
      deep: true
    }
  },
  methods: {
    handleItemToDelete (data) {
      this.dataToDelete.index = data
      this.dataToDelete.isDeleteDialog = true
    },
    getPrevElement (indexArray) {
      let localIndexArray
      if (indexArray.length === 1) {
        localIndexArray = false
      } else {
        localIndexArray = indexArray.slice(0, -1)
      }
      return localIndexArray
    },
    updateSelected () {
      let indexToDelete = cleanClone(this.dataToDelete.index)
      let indexSelected = cleanClone(this.customIndex)
      if (indexToDelete.length < indexSelected.length) {
        indexSelected = indexSelected.slice(indexToDelete.length)
      }
      if (deepEqual(indexToDelete, indexSelected)) {
        let prevItem = this.getPrevElement(this.dataToDelete.index)
        if (prevItem) {
          this.customIndex = prevItem
        } else {
          if (this.eavEntity.eavAttributes.length > 1) {
            if (this.customIndex[0] >= this.eavEntity.eavAttributes.length - 1) {
              this.customIndex = [this.eavEntity.eavAttributes.length - 2]
            }
          } else {
            this.baseIndex = [0]
          }
        }
      }
    },
    async handleDelete () {
      this.updateSelected()
      return this.$nextTick(() => {
        recLookup(this.eavEntity.eavAttributes, getParentPath(this.dataToDelete.index))
          .splice(this.dataToDelete.index.slice(-1)[0], 1)
        this.dataToDelete.index = null
        this.$emit('update:eavEntity', this.eavEntity)
      })
    },
    handleCreate (data = null) {
      let objectToAdd = this.eavEntity.eavAttributes
      if (data) {
        objectToAdd = recLookup(objectToAdd, getParentPath(data))[data.slice(-1)[0]].relatedAttributes
      }

      objectToAdd.push(new Attribute())

      if (data) {
        data.push(objectToAdd.length - 1)
      } else {
        data = [objectToAdd.length - 1]
      }
      this.customIndex = data
      this.$emit('update:eavEntity', this.eavEntity)
    }
  }
}
</script>

<style scoped>
  .active-item {
    background-color: rgba(211, 213, 216, 0.1);
  }
</style>
