import AttributeRule from '@/components/structures/constructor/attributeRule'
import AttributeType from '@/components/structures/constructor/attributeType'

class Attribute {
  constructor () {
    this.id = null
    this.name = ''
    this.label = 'Введите имя атрибута'
    this.order = null
    this.attributeRule = new AttributeRule()
    this.eavType = new AttributeType()
    this.defaultValue = null
    this.optionCategoryId = null
    this.parent_id = null
    this.show_in_table = false
    this.entityId = null
    this.is_filter = false
    this.relatedAttributes = []
  }
}

export default Attribute
