<template>
  <div style="max-width: 300px;">
    <v-btn
      icon
      v-for="(item, index) in packIcon"
      :key="index"
      @click="chooseIcon(index, item)"
      :color="value === item ? 'primary': ''"
    >
      <v-icon>{{item}}</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'BaseChooseIcon',
  props: {
    value: String
  },
  data () {
    return {
      packIcon: [
        'mdi-pine-tree',
        'mdi-leaf',
        'mdi-grass',
        'mdi-road-variant',
        'mdi-grain',
        'mdi-arch',
        'mdi-coach-lamp'
      ]
    }
  },
  methods: {
    chooseIcon (index, data) {
      this.$emit('input', data)
    }
  }
}
</script>

<style scoped>

</style>
