import { cleanClone, deepEqual } from '@/components/utils/common'

export default {
  props: {
    value: Object
  },
  data () {
    return {
      localAttribute: null
    }
  },
  watch: {
    localAttribute: {
      handler (newVal, oldVal) {
        if (JSON.stringify(newVal) !== JSON.stringify(this.value)) { //note: обычный deepEqual неверно сравнивает и уходит в бесконечную рекурсию, т.к. мы создаем массив заново
          let rules = [
            this.type
          ]
          let rulesObject = {}
          if (this.localAttribute.attributeRule.rules && this.localAttribute.attributeRule.rules.length) {
            for (let key in this.localAttribute.attributeRule.rules[1]) {
              if (this.localAttribute.attributeRule.rules[1].hasOwnProperty(key) && this.localAttribute.attributeRule.rules[1][key]) {
                rulesObject[key] = this.localAttribute.attributeRule.rules[1][key]
              }
            }

            if (Object.keys(rulesObject).length) {
              rules.push(rulesObject)
            }
            this.localAttribute.attributeRule.rules = rules
          }

          this.$emit('input', cleanClone(this.localAttribute))
        }
      },
      deep: true
    },
    value: {
      handler (newVal, oldVal) {
        if (!deepEqual(newVal, this.localAttribute)) {
          if (this.value) {
            this.localAttribute = cleanClone(this.value)
          }
        }
      },
      immediate: true,
      deep: true
    }
  }
}
