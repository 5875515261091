<template>
  <div class="justify-space-between">
    <GroupAttributes
      v-if="item.eavType.id === 14"
      @delete="onDelete"
      @create="onCreate"
      :key="item.id"
      :item="item"
      :item-index="itemIndex"
      :active-index-list.sync="localIndexList"
    />
    <SimpleAttribute
      v-else
      @delete="onDelete"
      :key="item.id"
      :item="item"
      :item-index="itemIndex"
      :active-index.sync="activeIndex"
      :hide-actions="hideActions"
      :is-hide-type="isHideType"
    />
  </div>
</template>

<script>
import validationErrors from '@/components/mixins/validationErrors'
import SimpleAttribute from '@/components/views/account/constructor/detail/sideBar/SimpleAttribute'

export default {
  mixins: [validationErrors],
  name: 'SidebarItem',
  components: {
    SimpleAttribute,
    GroupAttributes: () => import('./GroupAttributes.vue') //note: регистрация рекурсивного компонента. Внутри GroupAttributes вызывается SidebarItem
  },
  props: {
    activeIndexList: Array,
    item: Object,
    itemIndex: Number,
    hideActions: {
      type: Boolean,
      default: false
    },
    isHideType: Boolean
  },
  computed: {
    localIndexList: {
      get () {
        return this.activeIndexList
      },
      set (val) {
        this.$emit('update:activeIndexList', val)
      }
    },
    activeIndex: {
      get () {
        return this.activeIndexList[0]
      },
      set (val) {
        this.localIndexList = [val]
      }
    }
  },
  methods: {
    onDelete (index) {
      this.$emit('delete', index)
    },
    onCreate (index) {
      this.$emit('create', index)
    }
  }
}
</script>

<style scoped>

</style>
