import CyrillicToTranslit from 'cyrillic-to-translit-js'
import { cleanClone, deepEqual } from '@/components/utils/common'

const cyrillicToTranslit = new CyrillicToTranslit()

function filterTranslit (str) {
  const regexp = /[^A-Za-zА-Яа-я0-9_]/g //note: Кирилл - перечислил список валидных символов, остальные меняем на "_" (^ - вот этот значек - отрицание)
  return str.replace(regexp, '_')
}

export default {
  props: {
    value: Object
  },
  inject: ['$v'],
  data () {
    return {
      localAttribute: cleanClone(this.value)
    }
  },
  watch: {
    value: {
      handler (newVal) {
        if (!deepEqual(newVal, this.localAttribute)) {
          this.localAttribute = cleanClone(this.value)
        }
      },
      deep: true
    }
  },
  methods: {
    handleLocalAttributeChange () {
      this.$emit('input', cleanClone(this.localAttribute))
    },
    textTransform (resource) {
      this.$set(this.localAttribute, 'name', filterTranslit(cyrillicToTranslit.transform(resource, '_').toLowerCase()))
    },
    labelToName () {
      if (this.localAttribute.label && !this.localAttribute.name) {
        this.textTransform(this.localAttribute.label)
      }
    },
    async handleLabelChange () {
      return this.$nextTick(() => {
        this.labelToName()
        this.handleLocalAttributeChange()
        return true
      })
    },
    async handleNameChange (data) {
      return this.$nextTick(() => {
        data ? this.textTransform(data) : this.labelToName()
        this.handleLocalAttributeChange()
        return true
      })
    }
  }
}
