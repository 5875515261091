<template>
  <BaseTree
    v-model="localAttribute.optionCategoryId"
    :category-to-filter="categoryToFilter"
  />
</template>

<script>

import { cleanClone, deepEqual } from '@/components/utils/common'

export default {
  name: 'OptionSettings',
  components: { BaseTree: () => import('@/components/base/BaseTree') },
  props: {
    value: Object,
    categoryToFilter: Number
  },
  data () {
    return {
      localAttribute: cleanClone(this.value)
    }
  },
  watch: {
    localAttribute: {
      handler () {
        this.$emit('input', cleanClone(this.localAttribute))
      },
      deep: true
    },
    value: {
      handler (newVal, oldVal) {
        if (!deepEqual(newVal, oldVal)) {
          this.localAttribute = cleanClone(this.value)
        }
      },
      deep: true
    }
  }
}
</script>

<style scoped>

</style>
