<template>
  <v-list-item
    v-else
    :class="getClass"
    class="d-flex justify-space-between flex-row pointer"
    inactive
    @click="handleClick"
  >
    <v-list-item-content>
      <v-list-item-title>{{ item.label }}</v-list-item-title>
      <v-list-item-subtitle
        v-if="!isHideType"
        class="type-label"
      >{{ typeLabel(item.eavType) }}
      </v-list-item-subtitle>
      <div>
        <v-tooltip
          v-for="element in iconsAttribute(item)"
          :key="element.id"
          v-if="element.show"
          bottom
          max-width="250"
        >
          <template v-slot:activator="{ on }">
              <span
                class="mr-2"
                v-on="on"
              >
                <v-icon small>{{ element.icon }}</v-icon>
              </span>
          </template>
          {{ element.tip }}
        </v-tooltip>
      </div>
    </v-list-item-content>
    <v-list-item-action v-if="!hideActions">
      <!--note: @click.stop отменяет всплывание события, т.е. выделения элемента не происходит-->
      <v-btn
        :disabled="!$can(null, 'editConstructor')"
        small
        icon
        @click.stop="handleDelete"
      >
        <v-icon small>mdi-trash-can-outline</v-icon>
      </v-btn>
    </v-list-item-action>
  </v-list-item>
</template>

<script>
import { iconsFromAttribute } from '@/components/utils/common'

export default {
  name: 'SimpleAttribute',
  props: {
    activeIndex: Number,
    itemIndex: Number,
    item: Object,
    hideActions: Boolean,
    isHideType: Boolean
  },
  computed: {
    eavAttributeTypes () {
      return this.$store.getters['serviceData/get']('eavAttributeType.types') || []
    },
    localActiveIndex: {
      get () {
        return this.activeIndex
      },
      set (val) {
        this.$emit('update:activeIndex', val)
      }
    },
    getClass () {
      /*const isActiveClass = this.activeIndexList.includes(this.itemIndex) ? 'active-item' : '' //перезапись стилей для активного элемента.
      return this.checkErrors(`detail.eavAttributes.$each.${this.itemIndex}`).length ? 'error ' + isActiveClass : ' ' + isActiveClass*/
      return this.activeIndex === this.itemIndex ? 'active-item' : ''
    }
  },
  methods: {
    iconsAttribute (value) {
      return iconsFromAttribute(value)
    },
    typeLabel (value) {
      const type = this.eavAttributeTypes.find((item) => item.id === value.id)
      return type?.label
    },
    handleClick() {
      this.localActiveIndex = this.itemIndex
    },
    handleDelete() {
      this.$emit('delete', [this.itemIndex])
    }
  }
}
</script>

<style scoped>
  .v-item-group .v-list-item.active-item {
    background-color: rgba(211, 213, 216, 0.1) !important;
  }
  .error {
    border: solid 1px red;
  }
  .type-label {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 180px;
  }
</style>
