<template>
</template>

<script>
export default {
  name: 'Empty'
}
</script>

<style scoped>

</style>
