<template>
  <v-card
    flat
    :key="value.name"
    :disabled="selectedItem.type === 'base'"
  >
    <v-card-text>
      <div class="d-flex">
        <div
          class="mr-5"
          style="width: 50%"
        >
          <v-text-field
            outlined
            dense
            v-model="localAttribute.label"
            @change="handleLabelChange"
            :label="$t('constructor.label')"
            :error-messages="getErrorMessage(getValidationPath + '.label')"
          ></v-text-field>
          <v-text-field
            outlined
            dense
            counter="31"
            maxlength="31"
            v-model="localAttribute.name"
            @change="handleNameChange"
            label="Алиас"
            :error-messages="getErrorMessage(getValidationPath + '.name')"
          ></v-text-field>
          <v-select
            dense
            outlined
            v-model="localAttribute.eavType"
            :items="eavAttributeTypes"
            item-value="id"
            item-text="label"
            return-object
            class="mt-13"
            :label="$t('constructor.type')"
          ></v-select>
          <v-text-field
            v-if="localAttribute.eavType.name !== 'multipleOption'"
            outlined
            dense
            v-model="localAttribute.order"
            @change="handleLocalAttributeChange"
            :label="$t('constructor.order')"
          ></v-text-field>
          <v-btn
            min-width="260"
            max-width="300"
            class="d-block text-no-wrap overflow-hidden entity-btn"
            style="text-overflow: ellipsis !important"
            depressed
            color="primary"
            v-if="(localAttribute.eavType.id === 9 || localAttribute.eavType.id === 8 || localAttribute.eavType.id === 2) && localAttribute.optionCategoryId"
            @click="isHandbookDialog = true"
          >
            <v-icon small>mdi-bookshelf</v-icon>
            {{ pathCategory }}
          </v-btn>
          <v-btn
            min-width="290"
            depressed
            style="border: none !important"
            :color="getErrorMessage(getValidationPath + '.relatedAttributes').length > 0 ? 'error' :'primary'"
            @click="isHandbookDialog = true"
            v-if="(localAttribute.eavType.id === 9 || localAttribute.eavType.id === 8 || localAttribute.eavType.id === 2) && !localAttribute.optionCategoryId"
          >
            <v-icon small>mdi-bookshelf</v-icon>
            Укажите справочник
          </v-btn>
        </div>

        <div class="d-flex flex-column mb-2">
          <!--note: для корректного отображения состояния чекбокса используем либо v-model, либо input-value. :value - работает некорректно-->
          <v-checkbox
            hide-details
            outlined
            dense
            :input-value="localAttribute.attributeRule.required"
            @change="handleRequired"
            :label="$t('constructor.required')"
            class="mt-0 pt-0"
            :disabled="localAttribute.eavType.id === 14"
          ></v-checkbox>
          <v-checkbox
            hide-details
            outlined
            dense
            :disabled="localAttribute.eavType.id === 14"
            v-model="localAttribute.attributeRule.locked"
            @change="handleLocalAttributeChange"
            :label="$t('constructor.locked')"
          ></v-checkbox>
          <v-checkbox
            hide-details
            :disabled="localAttribute.eavType.id === 9 || localAttribute.eavType.id === 14"
            outlined
            dense
            v-model="localAttribute.show_in_table"
            @change="handleLocalAttributeChange"
            :label="$t('constructor.showInTable')"
          ></v-checkbox>
          <v-checkbox
            hide-details
            outlined
            dense
            v-model="localAttribute.is_filter"
            @change="handleLocalAttributeChange"
            :disabled="localAttribute.eavType.id === 9 || localAttribute.eavType.id === 14"
            :label="$t('constructor.filter')"
          >
          </v-checkbox>
          <div
            class="mt-2 mb-2"
            v-if="value.eavType.id !== 14"
          >
            <BaseBtn
              title="Переместить в..."
              icon="mdi-file-move"
              color="primary"
              small
              @click="isMovingDialog = true"
            />
          </div>
        </div>
      </div>

      <component
        v-model="localAttributeInput"
        @change="handleLocalAttributeChange"
        :is="componentName"
        :category-to-filter="typeByLabel('Простой список')"
      />

      <RelatedAttributesList
        v-if="localAttribute.eavType.id === 9"
        v-model="localAttribute.relatedAttributes"
        @input="handleLocalAttributeChange"
        :validation-path="getValidationPath"
        :option-category-id="localAttribute.optionCategoryId"
        :parent-id="localAttribute.id"
        :entity-id="localAttribute.entityId"
        @changeFilter="handleIsFilter"
        @changeShowInTable="handleShowInTable"
      />
    </v-card-text>

    <BaseDialog
      v-if="isHandbookDialog"
      :value="isHandbookDialog"
      @input="isHandbookDialog = false"
      label="Справочник"
      prepend-icon-label="mdi-bookshelf"
      text-confirm="Готово"
      @confirm="isHandbookDialog = false"
      is-cross-close
      :max-width="400"
    >
      <template #content>
        <component
          v-model="localAttributeInput"
          :is="multipleName"
          :category-to-filter="typeByLabel('Простой список')"
        />
      </template>
    </BaseDialog>
    <BaseDialog
      label="Перемещение"
      :max-width="600"
      is-cross-close
      :value="isMovingDialog"
      v-if="isMovingDialog"
      @input="isMovingDialog = !isMovingDialog"
    >
      <template #content>
        <v-list>
          <v-list-item
            v-if="value.parent_id"
            class="item_moving"
            @click="moveAttributeIntoMain"
          >Основной список
          </v-list-item>
          <v-list-item
            class="item_moving"
            :key="item.id"
            v-for="item in filteredEavAttributes"
            @click="handleMoveAttribute(item)"
          >{{ item.label }} ({{ item.name }})
          </v-list-item>
        </v-list>
      </template>
    </BaseDialog>
  </v-card>
</template>

<script>
import NumericSettings from '@/components/views/account/constructor/detail/attributeSettings/NumericSettings'
import StringSettings from '@/components/views/account/constructor/detail/attributeSettings/StringSettings'
import OptionSettings from '@/components/views/account/constructor/detail/attributeSettings/OptionSettings'
import ComplexOptionSettings
  from '@/components/views/account/constructor/detail/attributeSettings/ComplexOptionSettings'
import validationErrors from '@/components/mixins/validationErrors'
import AttributeRule from '@/components/structures/constructor/attributeRule'
import serviceDataComputeds from '@/components/mixins/serviceDataComputeds'
import Empty from '@/components/views/account/constructor/detail/attributeSettings/Empty'
import RelatedAttributesList
  from '@/components/views/account/constructor/detail/attributeSettings/complexOptionSettings/RelatedAttributesList'
import constructorAttributes from '@/components/mixins/constructorAttributes'
import BaseDialog from '@/components/base/BaseDialog'
import BaseBtn from '@/components/base/UI/BaseBtn'

export default {
  mixins: [validationErrors, serviceDataComputeds, constructorAttributes],
  name: 'AttributeSettings',
  components: {
    BaseBtn,
    BaseDialog,
    NumericSettings,
    StringSettings,
    OptionSettings,
    ComplexOptionSettings,
    Empty,
    RelatedAttributesList
  },
  props: {
    selectedItem: Object,
    entityName: String,
    eavAttributes: Array
  },
  inject: ['$v'],
  data () {
    return {
      isHandbookDialog: false,
      isMovingDialog: false
    }
  },
  computed: {
    filteredEavAttributes () {
      return this.eavAttributes.filter((item) => item.id !== this.value.parent_id && item.eavType.id === 14)
    },
    pathCategory () {
      let result = ''
      if (this.localAttributeInput?.optionCategoryId && this.localAttributeInput) {
        const category = this.$store.getters['handbook/getListItemById'](this.localAttributeInput.optionCategoryId) //категория текущего списка
        const categoryParent = this.$store.getters['handbook/getCategoryParent'](this.localAttributeInput.optionCategoryId)?.name // родитель текущего списка
        result = categoryParent ? (String(categoryParent) + '/' + category?.name) : category?.name
      }
      return result
    },
    getValidationPath () {
      let path = ''
      this.selectedItem.index.forEach((item, index) => {
        path += item
        if (this.selectedItem.index.length - 1 !== index) {
          path += '.relatedAttributes.$each.'
        }
      })
      return path
    },
    localAttributeInput: {
      get () {
        return this.localAttribute
      },
      set (val) {
        this.isHandbookDialog = false
        this.$set(this, 'localAttribute', { ...val })
        this.handleLocalAttributeChange()
      }
    },
    eavAttributeTypes () {
      let eavTypes = this.$store.getters['serviceData/get']('eavAttributeType.types') || []
      if (this.selectedItem.index.length > 1) {
        eavTypes = eavTypes.filter((item) => Number(item.id) !== 14) //убрать "группа атрибутов" из списка типов, если мы уже в группе атрибутов
      }
      eavTypes = eavTypes.filter((item) => Number(item.id) !== 10)
      return eavTypes
    },
    componentName () {
      let name = null
      switch (this.localAttribute.eavType.id) {
        case 7:
          name = 'NumericSettings'
          break
        case 1:
          name = 'StringSettings'
          break
        case 10:
          name = 'Empty'
          break
        case 11:
          name = 'Empty'
          break
      }
      return name
    },
    multipleName () {
      let name = null
      switch (this.localAttribute.eavType.id) {
        case 9:
          name = 'ComplexOptionSettings'
          break
        case 8:
          name = 'OptionSettings'
          break
        case 2:
          name = 'OptionSettings'
          break
      }
      return name
    }
  },
  watch: {
    'localAttribute.eavType.id': {
      handler () {
        this.localAttribute.attributeRule = new AttributeRule(this.localAttribute.eavType.id)
        this.handleLocalAttributeChange()
      }
    }
  },
  methods: {
    handleMoveAttribute (item) {
      this.$emit('moveAttribute', item)
      this.isMovingDialog = false
    },
    moveAttributeIntoMain() {
      this.$emit('moveAttributeIntoMain', this.value)
      this.isMovingDialog = false
    },
    getErrorMessage (path) {
      let errors = []
      if (this.selectedItem.type === 'custom') {
        errors = this.checkErrors(`detail.eavAttributes.$each.${path}`)
      }
      return errors
    },
    handleIsFilter (data) {
      if (Boolean(this.localAttribute.is_filter) !== data) {
        this.localAttribute.is_filter = data
        this.handleLocalAttributeChange()
      }
    },
    handleShowInTable (data) {
      if (Boolean(this.localAttribute.show_in_table) !== data) {
        this.localAttribute.show_in_table = data
        this.handleLocalAttributeChange()
      }
    },
    handleRequired (data) {
      if (this.localAttribute.attributeRule.required !== data) {
        this.localAttribute.attributeRule.required = data
        this.localAttribute.relatedAttributes.forEach(attribute => {
          attribute.attributeRule.required = data
        })
        this.handleLocalAttributeChange()
      }
    }
  }
}
</script>

<style scoped>
  /deep/ .entity-btn .v-btn__content {
    overflow: hidden;
    white-space: nowrap;
    display: block;
    text-overflow: ellipsis;
  }
  .item_moving:hover {
    background-color: #cde4eb;
  }
  .item_moving:focus, /deep/ .item_moving:focus.v-list-item {
    background-color: #90c2e5;
    color: #ffffff !important;
  }
</style>
