<template>
  <v-text-field
    style="width: 50%"
    outlined
    dense
    type="number"
    v-model="rulesInput"
    :label="$t('constructor.max')"
  ></v-text-field>
</template>

<script>
import attributeRules from '@/components/mixins/attributeRules'

export default {
  mixins: [attributeRules],
  name: 'StringSettings',
  data () {
    return {
      type: 'string',
    }
  },
  computed: {
    rulesInput: {
      get () {
        return this.localAttribute.attributeRule.rules.length > 1 ? this.localAttribute.attributeRule.rules[1].max : null
      },
      set (val) {
        if (val) {
          if (this.localAttribute.attributeRule.rules.length === 1) {
            this.localAttribute.attributeRule.rules.push({})
          }
          this.$set(this.localAttribute.attributeRule.rules[1], 'max', val)
        } else {
          this.localAttribute.attributeRule.rules.splice(1, 1)
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
