class AttributeRule {
  static availableRules = {
    1: ['string'], // text
    2: ['integer'], // option
    7: ['integer'], // number
    8: ['safe'], // multiple option
    9: ['integer'], // complex option
    10: ['safe'], // coordinates
    14: null
  }
  constructor (typeId = 1) {
      this.rules = this.constructor.availableRules[typeId] // ["string", {"max": 255}]
      this.required = false
      this.visible = true
      this.locked = false
  }
}

export default AttributeRule
