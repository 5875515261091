<template>
  <div>
    <v-card-text>
      <div class="d-flex align-center justify-start">
        <v-text-field
          dense
          class="mr-4"
          v-model="entityName"
          outlined
          hide-details
          :label="$t('message.enterName')"
        ></v-text-field>
        <v-avatar
          @click="isOpen=!isOpen"
          class="mr-2 pointer"
          :color="color"
          min-width="61"
          min-height="61"
        >
          <v-icon
            color="#FFF"
          >{{ icon }}
          </v-icon>
        </v-avatar>
      </div>
      <div>
        <p
          class="orange--text"
          v-if="!this.localStyles.entityGeometry"
        >Должен быть выбран хотя бы 1 тип</p>
        <v-checkbox
          label="Точка"
          hide-details
          v-model="typesGeometry.Point"
          dense
        ></v-checkbox>
        <v-checkbox
          label="Линия"
          hide-details
          v-model="typesGeometry.LineString"
          dense
        ></v-checkbox>
        <v-checkbox
          label="Полигон"
          hide-details
          v-model="typesGeometry.Polygon"
          dense
        ></v-checkbox>
      </div>
      <BaseDialog
        :value="isOpen"
        :max-width="350"
        :label="$t('message.chooseColor')"
        :is-confirm-btn="isCorrectIcon"
        is-cancel-btn
        :text-cancel="$t('base.cancel')"
        @confirm="confirmStyles"
        @input="cancelStyles"
      >
        <template #content>
          <v-color-picker
            hide-inputs
            mode="hexa"
            class="mt-1 mb-1 pl-0"
            v-model="color"
            width="340"
            flat
          ></v-color-picker>
          <v-card-text class="pa-0">
            <div
              style="font-size: 20px !important"
              class="title font-weight-bold text-heading-6 pa-0 mb-2"
            >{{ $t('message.chooseIcon') }}
            </div>
            <BaseChooseIcon v-model="icon"/>
          </v-card-text>
          <p class="font-weight-bold mt-2 mb-0">Подбор иконки можно сделать на этом сайте:
            <a
              target="_blank"
              class="mb-2 font-weight-medium"
              href="https://pictogrammers.com/library/mdi/"
            >https://pictogrammers.com/library/mdi/</a>
          </p>

          <p class="mb-0 mt-2 blue-grey--text">Укажите название иконки(например: abacus)</p>

          <v-text-field
            v-model="icon"
            outlined
            hide-details
            dense
          ></v-text-field>
          <v-progress-linear
            rounded
            height="6"
            indeterminate
            v-if="isChecking"
            class="mt-1"
          ></v-progress-linear>
          <div
            class="d-flex justify-center mt-2"
          >
            <v-icon
              size="65"
              id="iconInput"
              :color="color"
            >{{ iconInput }}
            </v-icon>
          </div>
          <p
            class="d-flex justify-center mb-0"
            v-if="!isCorrectIcon"
          >Иконка не найдена</p>
        </template>
      </BaseDialog>
    </v-card-text>
  </div>
</template>

<script>
import BaseChooseIcon from '@/components/base/BaseChooseIcon'
import { cleanClone, deepEqual } from '@/components/utils/common'
import BaseDialog from '@/components/base/BaseDialog'

export default {
  name: 'EavEntityEdit',
  components: { BaseDialog, BaseChooseIcon },
  props: {
    value: Object
  },
  data () {
    return {
      isOpen: false,
      defaultStyle: {
        color: '#000000',
        icon: 'mdi-flower'
      },
      triggerColor: 1,
      localStyles: cleanClone(this.value),
      iconInput: null,
      isCorrectIcon: true,
      timeCheck: null,
      isChecking: false,
      typesGeometry: {
        Point: false,
        Polygon: false,
        LineString: false
      }
    }
  },
  computed: {
    entityName: {
      get () {
        return this.value.entityName
      },
      set (val) {
        this.$set(this.localStyles, 'entityName', val)
        this.$emit('input', { ...this.value, entityName: val })
      }
    },
    color: {
      get () {
        if (this.triggerColor) {
          return this.localStyles.style?.hasOwnProperty('color') ? this.localStyles.style.color : this.defaultStyle.color
        }
      },
      set (val) {
        let value = cleanClone(this.localStyles)
        //note: с сервера, если нет стиля у объекта летит пустой массив (в пыхе json в именованный массив превращается. пустой объект и пустой массив не различаются)
        if (Array.isArray(value.style)) {
          value.style = { weight: 2, fill: true }
        }
        value.style.color = val
        this.triggerColor += 1 // Для визуального обновления цвета. Не работает без этого, потому что vue не видит изменения во вложенном объекте style.color
        this.$set(this.localStyles, 'style', value.style)
      }
    },
    icon: {
      get () {
        return this.localStyles.icon ? this.localStyles.icon : this.defaultStyle.icon
      },
      set (val) {
        this.iconInput = null
        setTimeout(() => this.localStyles.icon = val)
        this.iconInput = val//кладу в стек, чтобы сначала среагировал watch для iconInput
      }
    }
  },
  watch: {
    value: {
      handler () {
        if (!deepEqual(this.value, this.localStyles)) {
          this.localStyles = cleanClone(this.value)
        }
      },
      deep: true
    },
    iconInput: {
      handler () {
        this.isChecking = true
        clearTimeout(this.timeCheck) //очистить таймер, чтобы прекратить предыдущую проверку
        this.timeCheck = setTimeout(() => {
          this.isCorrectIcon = this.checkIsCorrectIcon()
        }, 1000)
        if (this.iconInput && !this.iconInput.split('-').find((item) => item === 'mdi')) {
          this.iconInput = 'mdi-' + this.iconInput
        } else if (this.iconInput === 'mdi-' || this.iconInput === 'mdi') {
          this.iconInput = null
        }
        if (this.iconInput) {
          this.localStyles.icon = this.iconInput
        } else {
          this.localStyles.icon = this.value.icon
        }
      },
      deep: true,
      immediate: true
    },
    typesGeometry: {
      handler () {
        this.localStyles.entityGeometry = ''
        for (let geo in this.typesGeometry) {
          if (this.typesGeometry[geo]) { // если true
            this.localStyles.entityGeometry += this.localStyles.entityGeometry ? ',' + geo : geo
          }
        }
        this.$emit('input', this.localStyles)
      },
      deep: true
    }
  },
  methods: {
    checkIsCorrectIcon () {
      let result = true
      const element = document.getElementById('iconInput')
      if (this.iconInput && element) { // если есть элемент и введена иконка
        result = window.getComputedStyle(element, ':before').content !== 'none'
      }
      this.isChecking = false
      return result
    },
    setDefaultStyles () {
      if (!this.value.icon) {
        this.localStyles.icon = this.defaultStyle.icon
      }
      if (!this.value.style?.hasOwnProperty('color')) {
        this.localStyles.style = { color: this.defaultStyle.color }
      }
      this.$emit('input', cleanClone(this.localStyles))
    },
    confirmStyles () {
      if (this.checkIsCorrectIcon()) {
        this.$emit('input', cleanClone(this.localStyles))
        this.isOpen = false
      } else {
        this.isCorrectIcon = false
      }
    },
    cancelStyles () {
      this.isOpen = false
      this.localStyles = cleanClone(this.value)
      this.iconInput = null
      this.isCorrectIcon = true
    }
  },
  mounted () {
    this.setDefaultStyles()
    if (this.localStyles.entityGeometry) {
      const getEntitiesGeometry = this.localStyles.entityGeometry.split(',')
      getEntitiesGeometry.forEach((geo) => this.typesGeometry[geo] = true)
    }
  }
}
</script>

<style scoped>
  /deep/ .v-color-picker__controls {
    padding: 8px 0 4px;
  }
</style>
