import store from '@/store'

function types () {
  return store.getters['serviceData/get']('eavAttributeType.types') || []
}

class AttributeType {
  constructor (typeId = 1) {
    const type = types().find(item => item.id === typeId)
    if (!type) {
      throw new Error('необходимо указать тип атрибута')
    }
    this.id = type.id
  }
}

export default AttributeType
