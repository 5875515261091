import { maxLength, required } from 'vuelidate/lib/validators'
//note: захардкодил, т.к. в системе на данном этапе ограниченная вложенность атриутов
export default {
  validations() {
    return {
      detail: {
        eavAttributes: {
          $each: {
            label: {
              required
            },
            name: {
              required,
              maxLength: maxLength(31)
            },
            relatedAttributes: {
              $each: {
                label: {
                  required
                },
                name: {
                  required,
                  maxLength: maxLength(31)
                },
                relatedAttributes: {
                  $each: {
                    label: {
                      required
                    },
                    name: {
                      required,
                      maxLength: maxLength(31)
                    },
                    relatedAttributes: {}
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
