<template>
  <EavEntityEdit
    v-model="lEavEntity"
  />
</template>

<script>
import EavEntityEdit from '@/components/views/account/constructor/detail/sideBar/EavEntityEdit'

export default {
  name: 'DetailOwn',
  components: { EavEntityEdit },
  props: {
    eavEntity: Object,
    selectedItem: Object
  },
  computed: {
    lEavEntity: {
      get () {
        return this.eavEntity
      },
      set (val) {
        this.$emit('update:eavEntity', val)
      }
    }
  }
}
</script>
