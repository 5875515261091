<template>
  <BaseCard v-if="value">
    <template #header>
    </template>
    <template #content>
      <AttributeSettings
        v-model="valueInput"
        :selected-item="selectedItem"
        :entityName="eavEntity.entityName"
        :eav-attributes="eavEntity.eavAttributes"
        @moveAttribute="$emit('moveAttribute', $event)"
        @moveAttributeIntoMain="$emit('moveAttributeIntoMain', $event)"
      />
    </template>
  </BaseCard>
</template>

<script>
import AttributeSettings from '@/components/views/account/constructor/detail/AttributeSettings'
import BaseCard from '@/components/base/BaseCard'

export default {
  name: 'DetailView',
  components: { BaseCard, AttributeSettings },
  props: {
    value: Object,
    selectedItem: Object,
    eavEntity: Object
  },
  inject: ['$v'],
  computed: {
    valueInput: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  }
}
</script>

<style scoped>

</style>
