<template>
  <div style="max-width: 50%">
    <v-text-field
      outlined
      dense
      type="number"
      v-model="rulesMinInput"
      :label="$t('constructor.min')"
    ></v-text-field>
    <v-text-field
      outlined
      dense
      type="number"
      v-model="rulesMaxInput"
      :label="$t('constructor.max')"
    ></v-text-field>
  </div>
</template>

<script>
import attributeRules from '@/components/mixins/attributeRules'

export default {
  mixins: [attributeRules],
  name: 'NumericSettings',
  data () {
    return {
      type: 'integer'
    }
  },
  computed: {
    rulesMinInput: {
      get () {
        return this.localAttribute.attributeRule.rules.length > 1 ? this.localAttribute.attributeRule.rules[1].min : null
      },
      set (val) {
        if (val) {
          if (this.localAttribute.attributeRule.rules.length === 1) {
            this.localAttribute.attributeRule.rules.push({})
          }
          this.$set(this.localAttribute.attributeRule.rules[1], 'min', val)
        } else {
          if (this.localAttribute.attributeRule.rules[1].max === undefined) {
            this.localAttribute.attributeRule.rules.splice(1, 1)
          } else {
            this.$delete(this.localAttribute.attributeRule.rules[1], 'min')
          }
        }
      }
    },
    rulesMaxInput: {
      get () {
        return this.localAttribute.attributeRule.rules.length > 1 ? this.localAttribute.attributeRule.rules[1].max : null
      },
      set (val) {
        if (val) {
          if (this.localAttribute.attributeRule.rules.length === 1) {
            this.localAttribute.attributeRule.rules.push({})
          }
          this.$set(this.localAttribute.attributeRule.rules[1], 'max', val)
        } else {
          if (this.localAttribute.attributeRule.rules[1].min === undefined) {
            this.localAttribute.attributeRule.rules.splice(1, 1)
          } else {
            this.$delete(this.localAttribute.attributeRule.rules[1], 'max')
          }
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
