<template>
  <v-list-item
    class="pt-2 pb-2"
    @click="handleClick(0)"
    :class="getClass"
  >
    <v-avatar
      class="mr-2 rounded-lg"
      v-if="eavEntity && Object.keys(eavEntity).length > 0 && eavEntity.style"
      :color="eavEntity.style.color"
      min-width="20"
      min-height="20"
      tile
    >
      <v-icon
        small
        color="#FFF"
      >{{ eavEntity.icon }}
      </v-icon>
    </v-avatar>
    {{ eavEntity.entityName }}
  </v-list-item>
</template>

<script>
export default {
  name: 'OwnItems',
  props: {
    eavEntity: Object,
    activeIndexList: Array
  },
  computed: {
    localActiveIndex: {
      get () {
        return this.activeIndexList
      },
      set (val) {
        this.$emit('update:activeIndexList', val)
      }
    },
    getClass () {
      return this.activeIndexList.length > 0 ? 'active-item' : ''
    }
  },
  methods: {
    handleClick (index) {
      this.localActiveIndex = [index]
    }
  }
}
</script>

<style scoped>
/deep/ .v-list-item.active-item {
  background-color: rgba(197, 209, 227, 0.1) !important;
}
</style>